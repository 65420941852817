export const LINK = {
  INFORMATION_SECURITY_POLICY: {
    title: "Chính sách bảo mật thông tin",
    link: "https://www.notion.so/Chi-nh-sa-ch-ba-o-ma-t-tho-ng-tin-a0c233eabe8f4693b89d48dd42cfb2b7?pvs=4",
  },
  OPERATING_POLICIES_AND_GENERAL_REGULATIONS_OF_THE_WEBSITE: {
    title: "Chính sách hoạt động và quy định chung của Website",
    link: "https://www.notion.so/Chi-nh-sa-ch-hoa-t-o-ng-va-quy-i-nh-chung-cu-a-Website-c6877d061cb24a5aa1158da77470c080?pvs=4",
  },
} as const;
