import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Card, ErrMsg, FormFileInput, LabelContent } from "@/components";
import type { Languages } from "@/types";

import type { SignupForm } from "types";
import * as S from "./BusinessLicense.styled";

interface BusinessLicenseProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (fileKey: string) => () => void;
}

const BusinessLicense = ({
  handleFileChange,
  handleFileDelete,
}: BusinessLicenseProps) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext<SignupForm>();

  return (
    <Card.Content css={S.card} heading="ERC">
      <LabelContent label="Front of ERC" direction="vertical" isRequired>
        <FormFileInput
          hasErr={!!errors.license1}
          accept="image/jpg, image/jpeg, image/png"
          file={watch("license1")}
          fileKey="license1"
          placeholder="Upload front of ERC files"
          register={register}
          handleInputChange={handleFileChange}
          handleDeleteFile={handleFileDelete}
        />
        <S.FileInfo>{`- ${t(
          "File format: jpg, jpeg or png (Limit 5MB)",
        )}`}</S.FileInfo>
        {errors.license1?.message && (
          <ErrMsg>{errors.license1?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Back of ERC" direction="vertical" isRequired>
        <FormFileInput
          hasErr={!!errors.license2}
          accept="image/jpg, image/jpeg, image/png"
          file={watch("license2")}
          fileKey="license2"
          placeholder="Upload back of ERC files"
          register={register}
          handleInputChange={handleFileChange}
          handleDeleteFile={handleFileDelete}
        />
        <S.FileInfo>{`- ${t(
          "File format: jpg, jpeg or png (Limit 5MB)",
        )}`}</S.FileInfo>
        {errors.license2?.message && (
          <ErrMsg>{errors.license2?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <S.Divistion />
    </Card.Content>
  );
};

export default BusinessLicense;
