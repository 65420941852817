import { v4 as uuidv4 } from "uuid";

import type { S3FileUploadType, S3PresignedServerModel } from "@/types";

export const getS3FileFormData = (
  s3Info: S3PresignedServerModel,
  file: File,
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(s3Info.data.fields)) {
    formData.append(key, value);
  }
  formData.append("Content-type", file.type);
  formData.append("file", file);

  return formData;
};

export const makeNewFileName = (file: File) => {
  const uuid = uuidv4();
  const extension = file.name.split(".").at(-1);

  return `${uuid}.${extension}`;
};

export const makeFileUuid = (
  file: S3FileUploadType,
): { [fileKey: string]: string } => {
  return Object.entries(file).reduce((acc, [fileKey, { file }]) => {
    const fileUid = makeNewFileName(file!);

    return { ...acc, [fileKey]: fileUid };
  }, {} as { [fileKey: string]: string });
};

export const makeS3Promises = async (
  files: S3FileUploadType,
  postS3PresignedUrlAPI: (key: string, file: File) => Promise<any>,
) => {
  const S3Promises: Promise<any>[] = [];

  const fileUuids = Object.entries(files).reduce(
    (acc, [fileKey, { file, path }]) => {
      const fileUid = makeNewFileName(file!);

      S3Promises.push(postS3PresignedUrlAPI(`${path}/${fileUid}`, file!));

      return { ...acc, [fileKey]: fileUid };
    },
    {} as { [fileKey: string]: string },
  );

  return { fileUuids, S3Promises };
};
