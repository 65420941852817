import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckBtnBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const CheckBtnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: flex-start;
`;

export const checkBoxBtn = (theme: Theme) => css`
  ${theme.font.regular_14};
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
  margin-right: 8px;

  & > label {
    width: fit-content;
  }
`;

export const requiredCheckBoxBtn = (theme: Theme) => css`
  ${checkBoxBtn(theme)};

  & > label:last-of-type::after {
    content: "*";
    margin-left: 4px;
    color: ${theme.color.red_20};
  }
`;

export const Division = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_35};
  `}
`;
