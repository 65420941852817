import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Card, ErrMsg, Input, LabelContent, PasswordInput } from "@/components";
import {
  checkPasswordLength,
  checkPasswordValidation,
  numericOnly,
} from "@/utils";
import { CheckerIcon } from "@/assets";
import { COMMON_VALID_MSG } from "@/constants";
import type { Languages } from "@/types";

import type { SignupForm } from "types";
import * as S from "./UserInfo.styled";

const UserInfo = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext<SignupForm>();

  const password = watch("managerPassword");
  const passwordConfirm = watch("managerPasswordConfirm");

  return (
    <Card.Content css={S.card} heading="User information">
      <div>
        <S.NameInputWrapper
          hasError={
            !!errors.managerFirstName?.message ||
            !!errors.managerLastName?.message
          }
        >
          <LabelContent
            css={S.labelContent}
            label="Last name"
            direction="vertical"
            isRequired
          >
            <Input
              maxLength={100}
              placeholder="Last name"
              hasError={!!errors.managerLastName?.message}
              register={register("managerLastName")}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Middle name"
            direction="vertical"
          >
            <Input
              maxLength={100}
              placeholder="(Optional)"
              register={register("managerMiddleName")}
            />
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="First name"
            direction="vertical"
            isRequired
          >
            <Input
              maxLength={100}
              placeholder="First name"
              hasError={!!errors.managerFirstName?.message}
              register={register("managerFirstName")}
            />
          </LabelContent>
        </S.NameInputWrapper>
        {(errors.managerFirstName?.message ||
          errors.managerLastName?.message) && (
          <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
        )}
      </div>
      <LabelContent label="Phone number" direction="vertical" isRequired>
        <Input
          maxLength={20}
          value={watch("managerPhone")}
          hasError={!!errors.managerPhone?.message}
          placeholder="Only numbers"
          register={register("managerPhone", { setValueAs: numericOnly })}
        />
        {errors.managerPhone?.message && (
          <ErrMsg>{errors.managerPhone?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Login ID" direction="vertical" isRequired>
        <Input
          maxLength={100}
          hasError={!!errors.managerEmail?.message}
          placeholder="Please enter email address."
          register={register("managerEmail")}
        />
        {errors.managerEmail?.message && (
          <ErrMsg>{errors.managerEmail?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Password" direction="vertical" isRequired>
        <PasswordInput
          id="managerPassword"
          placeholder="Enter password"
          value={watch("managerPassword")}
          hasError={!!errors.managerPassword?.message}
          register={register("managerPassword")}
        />
        <S.PasswordLengthHint
          isLength={checkPasswordLength(password)}
          hasError={
            !!errors.managerPassword?.message ||
            (!checkPasswordLength(password) && password.length !== 0)
          }
        >
          <CheckerIcon />
          <span>{t("8-16 characters.")}</span>
        </S.PasswordLengthHint>
        <S.PasswordTypeHint
          isType={checkPasswordValidation(password)}
          hasError={
            !!errors.managerPassword?.message ||
            (!checkPasswordValidation(password) && password.length !== 0)
          }
        >
          <CheckerIcon />
          <span>{t("At least 2 types (A-Z, a-z, numbers, symbols).")}</span>
        </S.PasswordTypeHint>
      </LabelContent>
      <LabelContent label="Confirm password" direction="vertical" isRequired>
        <PasswordInput
          id="managerPasswordConfirm"
          placeholder="Confirm password"
          value={watch("managerPasswordConfirm")}
          hasError={!!errors.managerPasswordConfirm?.message}
          register={register("managerPasswordConfirm")}
        />
        <S.PasswordMatchHint
          isMatch={password === passwordConfirm && passwordConfirm.length !== 0}
          hasError={
            !!errors.managerPasswordConfirm?.message ||
            (password !== passwordConfirm && passwordConfirm.length !== 0)
          }
        >
          <CheckerIcon />
          <span>{t("Password matches.")}</span>
        </S.PasswordMatchHint>
      </LabelContent>
      <S.Divistion />
    </Card.Content>
  );
};

export default UserInfo;
