import React from "react";
import useScript from "react-script-hook";
import { useRecoilValue } from "recoil";

import { InternalLink } from "@/components";
import { clientLanguage } from "@/stores";
import { HeaderLogoIcon, LoginIcon, PATH } from "@/assets";

import * as S from "./CenterLayout.styled";

interface CenterLayoutProps {
  children: React.ReactNode;
}

const CenterLayout = ({ children }: CenterLayoutProps) => {
  const language = useRecoilValue(clientLanguage);
  const [loading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLEMAP_KEY}&libraries=places&language=${language}`,
  });

  return (
    <S.Root>
      {!loading && (
        <>
          <S.Header>
            <h1 className="a11y">COCOTRUCK Logistics</h1>
            <HeaderLogoIcon />
            <InternalLink variant="outlineMedium" to={PATH.ROOT}>
              <LoginIcon />
              <span>Đăng nhập</span>
            </InternalLink>
          </S.Header>
          <S.CenterLayout>{children}</S.CenterLayout>
        </>
      )}
    </S.Root>
  );
};

export default CenterLayout;
