import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const card = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const NameInputWrapper = styled.div<{ hasError: boolean }>`
  ${({ hasError }) => css`
    display: flex;
    column-gap: 10px;
    margin-bottom: ${hasError && "4px"};
  `}
`;

export const labelContent = css`
  width: 160px;
`;

export const Divistion = styled.hr`
  ${({ theme }) => css`
    margin: 16px 0 40px;
    border: 1px solid ${theme.color.gray_30};
  `}
`;

const PasswordHintWrapper = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    ${theme.font.regular_13};
    display: flex;
    align-items: center;

    span {
      color: ${hasError ? theme.color.red_20 : theme.color.gray_60};
    }

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      & > path {
        fill: ${hasError ? theme.color.red_20 : theme.color.gray_60};
      }
    }
  `}
`;

export const PasswordLengthHint = styled(PasswordHintWrapper)<{
  isLength: boolean;
}>`
  ${({ theme, isLength }) => css`
    span {
      color: ${isLength && theme.color.green_20};
    }

    & > svg > path {
      fill: ${isLength && theme.color.green_20};
    }
  `}
`;

export const PasswordTypeHint = styled(PasswordHintWrapper)<{
  isType: boolean;
}>`
  ${({ theme, isType }) => css`
    span {
      color: ${isType && theme.color.green_20};
    }

    & > svg > path {
      fill: ${isType && theme.color.green_20};
    }
  `}
`;

export const PasswordMatchHint = styled(PasswordHintWrapper)<{
  isMatch: boolean;
}>`
  ${({ theme, isMatch }) => css`
    span {
      color: ${isMatch && theme.color.green_20};
    }

    & > svg > path {
      fill: ${isMatch && theme.color.green_20};
    }
  `}
`;
