import React from "react";
import { useFormContext } from "react-hook-form";

import {
  Card,
  Input,
  LabelContent,
  AddrAutoComplete,
  ErrMsg,
} from "@/components";
import { numericOnly } from "@/utils";
import { COMMON_VALID_MSG } from "@/constants";
import type { AddressSearchType, Languages } from "@/types";

import { SignupForm } from "types";
import * as S from "./CompanyInfo.styled";

interface CompanyInfoProps {
  handleSelectAddr: (value: AddressSearchType) => void;
}

const CompanyInfo = ({ handleSelectAddr }: CompanyInfoProps) => {
  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext<SignupForm>();

  return (
    <Card.Content css={S.card} heading="Company information">
      <LabelContent label="Company name" direction="vertical" isRequired>
        <Input
          maxLength={100}
          hasError={!!errors.name?.message}
          placeholder="Enter company name"
          register={register("name")}
        />
        {errors.name?.message && (
          <ErrMsg>{errors.name?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Address" direction="vertical" isRequired>
        <AddrAutoComplete
          err={errors.addr}
          type="garage"
          value={watch("addr")}
          placeholder="Please enter your address or choose from map"
          handleSelect={handleSelectAddr}
        />
        {errors.addr?.address?.message && (
          <ErrMsg>{errors.addr.address?.message as Languages}</ErrMsg>
        )}
        <Input
          maxLength={100}
          placeholder="Detail address (Optional)"
          value={watch("addr.addressDetail")}
          register={register("addr.addressDetail")}
        />
      </LabelContent>
      <LabelContent label="Tax code" direction="vertical" isRequired>
        <Input
          maxLength={10}
          value={watch("taxcode")}
          hasError={!!errors.taxcode?.message}
          placeholder="10 letters, only numbers"
          register={register("taxcode", { setValueAs: numericOnly })}
        />
        {errors.taxcode?.message && (
          <ErrMsg>{errors.taxcode?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Business email" direction="vertical" isRequired>
        <Input
          maxLength={100}
          hasError={!!errors.email?.message}
          placeholder="Enter business email address"
          register={register("email")}
        />
        {errors.email?.message && (
          <ErrMsg>{errors.email?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Business tel" direction="vertical" isRequired>
        <Input
          maxLength={20}
          value={watch("phone")}
          hasError={!!errors.phone?.message}
          placeholder="Only numbers"
          register={register("phone", { setValueAs: numericOnly })}
        />
        {errors.phone?.message && (
          <ErrMsg>{errors.phone?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <S.Divistion />
    </Card.Content>
  );
};

export default CompanyInfo;
