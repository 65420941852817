import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Signup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 564px;
    border: 1px solid ${theme.color.gray_35};
    padding: 32px;
    background-color: ${theme.color.white};
  `}
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 12px;
  `}
`;
