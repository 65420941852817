import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    position: relative;
    min-width: 1200px;
    width: 100vw;
    min-height: calc(1080px - 52px);
    background-color: ${theme.color.gray_00};
  `}
`;

export const CenterLayout = styled.section`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 354px);
    margin: 0 auto;
    padding: 84px 0 80px 0;
    background-color: ${theme.color.gray_00};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 190px auto;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${theme.size.HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 0 ${theme.size.LAYOUT_PADDING} 0 20px;
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.HEADER};
  `}
`;

export const ifDesignIcon = css`
  position: absolute;
  bottom: 154px;
  right: 40px;
  z-index: 2;
`;
