import React, { useEffect } from "react";
import axios from "axios";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, CenterLayout } from "@/components";
import { useToast } from "@/hooks";
import { makeCryptoFunction, makeFileUuid } from "@/utils";
import { PATH } from "@/assets";
import type {
  Languages,
  S3FileUploadType,
  S3PresignedServerModel,
} from "@/types";

import { usePostSignup } from "services";
import { LOGISTICS_TOAST_MSG } from "constants/index";
import type { SignupForm } from "types";
import BusinessLicense from "./containers/businessLicense/BusinessLicense";
import CompanyInfo from "./containers/companyInfo/CompanyInfo";
import useSignupForm from "./containers/hooks/useSignupForm";
import TermsPolicyCheckBox from "./containers/TermsPolicyCheckBox/TermsPolicyCheckBox";
import TransportBusinessLicense from "./containers/transportBusinessLicense/TransportBusinessLicense";
import UserInfo from "./containers/userInfo/UserInfo";
import * as S from "./Signup.styled";

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: signupMutate } = usePostSignup();

  const { addToast } = useToast();
  const { formMethod, handleFileChange, handleFileDelete, handleSelectAddr } =
    useSignupForm();

  const handleSubmitSignup = async (data: SignupForm) => {
    const file: S3FileUploadType = {
      license1: { file: data.license1 && data.license1[0], path: "license" },
      license2: { file: data.license2 && data.license2[0], path: "license" },
      ...(data.transportLicense1 && {
        transportLicense1: {
          file: data.transportLicense1 && data.transportLicense1[0],
          path: "license",
        },
      }),
      ...(data.transportLicense2 && {
        transportLicense2: {
          file: data.transportLicense2 && data.transportLicense2[0],
          path: "license",
        },
      }),
    };
    const fileName = makeFileUuid(file);

    const body = {
      name: data.name,
      clientType: data.companyType,
      addr: data.addr.address,
      ...(data.addr.addressDetail && { addrDetail: data.addr.addressDetail }),
      lat: data.addr.coord.lat,
      lng: data.addr.coord.lng,
      taxcode: data.taxcode,
      email: data.email,
      phone: data.phone,
      license1: fileName.license1,
      license2: fileName.license2,
      ...(data.transportLicense1 && {
        transportLicense1: fileName.transportLicense1,
      }),
      ...(data.transportLicense2 && {
        transportLicense2: fileName.transportLicense2,
      }),
      managerPhone: data.managerPhone,
      managerEmail: data.managerEmail,
      managerPassword: makeCryptoFunction(data.managerPassword),
      managerFirstName: data.managerFirstName,
      ...(data.managerMiddleName && {
        managerMiddleName: data.managerMiddleName,
      }),
      managerLastName: data.managerLastName,
    };

    signupMutate(
      { body },
      {
        onSuccess: async (res) => {
          const S3Promises: Promise<any>[] = [];

          Object.entries(res)
            .filter(([key]) => {
              if (!body.transportLicense1 && !body.transportLicense2) {
                return (
                  key !== "transportLicense1" && key !== "transportLicense2"
                );
              }
              return true;
            })
            .forEach(([key, value]) => {
              if (value && file[key as keyof S3FileUploadType]) {
                const getS3FileFormData = (
                  s3Info: S3PresignedServerModel,
                  file: File,
                ) => {
                  const formData = new FormData();

                  for (const [key, value] of Object.entries(s3Info)) {
                    formData.append(key, value);
                  }
                  formData.append("Content-type", file.type);
                  formData.append("file", file);

                  return formData;
                };

                S3Promises.push(
                  axios.post(
                    value.url,
                    getS3FileFormData(
                      value.fields,
                      file[key as keyof S3FileUploadType].file as File,
                    ),
                  ),
                );
              }
            });

          const finalRes = await Promise.all(S3Promises);

          if (finalRes.every((res) => res.status === 204)) {
            addToast(
              LOGISTICS_TOAST_MSG.SUCCESS.CREATE_ACCOUNT_SUCCESS_VITENAM,
            );
            navigate(PATH.ROOT);
          }
        },

        onError: (err) => {
          const errCode = err.response?.data.response;

          switch (errCode) {
            case "EMAIL_EXISTS":
              addToast(LOGISTICS_TOAST_MSG.WARNING.COMPANY_EMAIL_EXISTS);
              break;
            case "PHONE_EXISTS":
              addToast(LOGISTICS_TOAST_MSG.WARNING.COMPANY_PHONE_EXISTS);
              break;
            case "STAFF_EMAIL_EXISTS":
              addToast(LOGISTICS_TOAST_MSG.WARNING.STAFF_EMAIL_EXISTS);
              break;
            case "STAFF_PHONE_EXISTS":
              addToast(LOGISTICS_TOAST_MSG.WARNING.STAFF_PHONE_EXISTS);
              break;
            case "TAXCODE_EXISTS":
              addToast(LOGISTICS_TOAST_MSG.WARNING.TAXCODE_EXISTS);
              break;
            default:
              addToast(LOGISTICS_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
          }
        },
      },
    );
  };

  useEffect(() => {
    i18n.changeLanguage("vi");
  }, []);

  return (
    <CenterLayout>
      <S.Signup>
        <S.Heading>{t("Sign up")}</S.Heading>
        <FormProvider {...formMethod}>
          <S.Section>
            <CompanyInfo handleSelectAddr={handleSelectAddr} />
            {(formMethod.watch("companyType") === "carrier" ||
              formMethod.watch("companyType") === "forwardercarrier") && (
              <TransportBusinessLicense
                handleFileChange={handleFileChange}
                handleFileDelete={handleFileDelete}
              />
            )}
            <BusinessLicense
              handleFileChange={handleFileChange}
              handleFileDelete={handleFileDelete}
            />
            <UserInfo />
            <TermsPolicyCheckBox />
            <Button
              type="submit"
              isDisabled={Object.keys(formMethod.formState.errors).length > 0}
              variant="primaryLarge"
              label={"Register" as Languages}
              handleClickBtn={formMethod.handleSubmit(handleSubmitSignup)}
            />
          </S.Section>
        </FormProvider>
      </S.Signup>
    </CenterLayout>
  );
};

export default Signup;
