import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { CheckboxBtn, ErrMsg, ExternalLink } from "@/components";
import { COMMON_VALID_MSG } from "@/constants";
import type { Languages } from "@/types";

import { LINK } from "constants/index";
import type { SignupForm } from "types";
import * as S from "./TermsPolicyCheckBox.styled";

const TermsPolicyCheckBox = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    clearErrors,
    watch,
    register,
    setValue,
    setError,
  } = useFormContext<SignupForm>();

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;

    setValue("checkTerms", checked);
    setValue("checkPolicy", checked);

    if (checked) {
      clearErrors("checkTerms");
      clearErrors("checkPolicy");
    } else {
      setError("checkTerms", {
        type: "required",
        message: COMMON_VALID_MSG.REQUIRED,
      });
      setError("checkPolicy", {
        type: "required",
        message: COMMON_VALID_MSG.REQUIRED,
      });
    }
  };

  const handleCheckTermsPolicy = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { checked } = e.target;

    if (!checked) {
      setValue("checkTermsPolicy", false);
    }

    if (watch("checkTerms") && watch("checkPolicy")) {
      setValue("checkTermsPolicy", true);
    }
  };

  return (
    <S.CheckBtnBox>
      <CheckboxBtn
        css={S.checkBoxBtn}
        shape="rectangle"
        label="Agree with all Terms and Conditions"
        isChecked={watch("checkTermsPolicy")}
        register={register("checkTermsPolicy", { onChange: handleAllCheck })}
      />
      <S.Division />
      <S.CheckBtnWrapper>
        <CheckboxBtn
          css={S.requiredCheckBoxBtn}
          shape="rectangle"
          label="Agree to the Information security policy"
          isChecked={watch("checkTerms")}
          register={register("checkTerms", {
            onChange: handleCheckTermsPolicy,
          })}
        />
        <ExternalLink
          variant="ghostGray"
          href={LINK.INFORMATION_SECURITY_POLICY.link}
        >
          {t("View all")}
        </ExternalLink>
      </S.CheckBtnWrapper>
      <S.CheckBtnWrapper>
        <CheckboxBtn
          css={S.requiredCheckBoxBtn}
          shape="rectangle"
          label="Agree to the Operating policies and general regulations of the Website"
          isChecked={watch("checkPolicy")}
          register={register("checkPolicy", {
            onChange: handleCheckTermsPolicy,
          })}
        />
        <ExternalLink
          variant="ghostGray"
          href={
            LINK.OPERATING_POLICIES_AND_GENERAL_REGULATIONS_OF_THE_WEBSITE.link
          }
        >
          {t("View all")}
        </ExternalLink>
      </S.CheckBtnWrapper>
      {(errors.checkTerms?.message || errors.checkPolicy?.message) && (
        <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
      )}
    </S.CheckBtnBox>
  );
};

export default TermsPolicyCheckBox;
