export const COMMON_ERROR_CODE = {
  ACCESSTOKEN_EXPIRED: "ACCESSTOKEN_EXPIRED",
  ALREADY_ACCEPTED: "ALREADY_ACCEPTED",
  ALREADY_SHIPPING: "ALREADY_SHIPPING",
  CONNECTED_TRUCK_EXISTS: "CONNECTED_TRUCK_EXISTS",
  DISPATCH_ALREADY_EXISTS: "DISPATCH_ALREADY_EXISTS",
  DRIVER_ALREADY_LINKED: "DRIVER_ALREADY_LINKED",
  DRIVING_DRIVER: "DRIVING_DRIVER",
  DRIVING_TRUCK: "DRIVING_TRUCK",
  DUPLICATE_SIGNIN_DETECTED: "DUPLICATE_SIGNIN_DETECTED",
  EMAIL_EXISTS: "EMAIL_EXISTS",
  EMAIL_NOT_FOUND: "EMAIL_NOT_FOUND",
  GARAGE_NAME_EXISTS: "GARAGE_NAME_EXISTS",
  ID_EXISTS: "ID_EXISTS",
  INVALID_MEMO: "INVALID_MEMO",
  INVALID_ORDERNUMBER: "INVALID_ORDERNUMBER",
  INVALID_PRICE: "INVALID_PRICE",
  INVALID_TRUCK_ID: "INVALID_TRUCK_ID",
  PHONE_EXISTS: "PHONE_EXISTS",
  PLATE_NUMBER_EXISTS: "PLATE_NUMBER_EXISTS",
  REFRESHTOKEN_EXPIRED: "REFRESHTOKEN_EXPIRED",
  STAFF_EMAIL_EXISTS: "STAFF_EMAIL_EXISTS",
  STAFF_PHONE_EXISTS: "STAFF_PHONE_EXISTS",
  TAXCODE_EXISTS: "TAXCODE_EXISTS",
  UNAUTHORIZED: "UNAUTHORIZED",
  VIN_EXISTS: "VIN_EXISTS",
  WRONG_PASSWORD: "WRONG_PASSWORD",
  WRONG_TRUCK_COUNT: "WRONG_TRUCK_COUNT",
} as const;
