import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SubTitle = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    margin-bottom: 16px;
    border-bottom: 1px solid ${theme.color.gray_70};
    padding-bottom: 12px;
  `}
`;

export const card = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Divistion = styled.hr`
  ${({ theme }) => css`
    margin: 16px 0 40px;
    border: 1px solid ${theme.color.gray_30};
  `}
`;

export const FileInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;
