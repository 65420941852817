import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Card, FormFileInput, LabelContent } from "@/components";

import type { SignupForm } from "types";
import * as S from "./TransportBusinessLicense.styled";

interface TransportBusinessLicenseProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (fileKey: string) => () => void;
}

const TransportBusinessLicense = ({
  handleFileChange,
  handleFileDelete,
}: TransportBusinessLicenseProps) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext<SignupForm>();

  return (
    <Card.Content
      css={S.card}
      heading="Business license"
      addHeadingElement={
        <S.RequiredText>
          {"*Tài liệu yêu cầu đối với công ty vận tải"}
        </S.RequiredText>
      }
    >
      <LabelContent
        label="Front of Transportation business license"
        direction="vertical"
      >
        <FormFileInput
          hasErr={!!errors.transportLicense1}
          accept="image/jpg, image/jpeg, image/png"
          file={watch("transportLicense1") || null}
          fileKey="transportLicense1"
          placeholder="Upload front of transportation business license"
          register={register}
          handleInputChange={handleFileChange}
          handleDeleteFile={handleFileDelete}
        />
        <S.FileInfo>{`- ${t(
          "File format: jpg, jpeg or png (Limit 5MB)",
        )}`}</S.FileInfo>
      </LabelContent>
      <LabelContent
        label="Back of Transportation business license"
        direction="vertical"
      >
        <FormFileInput
          hasErr={!!errors.transportLicense2}
          accept="image/jpg, image/jpeg, image/png"
          file={watch("transportLicense2") || null}
          fileKey="transportLicense2"
          placeholder="Upload back of transportation business license"
          register={register}
          handleInputChange={handleFileChange}
          handleDeleteFile={handleFileDelete}
        />
        <S.FileInfo>{`- ${t(
          "File format: jpg, jpeg or png (Limit 5MB)",
        )}`}</S.FileInfo>
      </LabelContent>
      <S.Divistion />
    </Card.Content>
  );
};

export default TransportBusinessLicense;
