import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const card = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const formRadioBtn = css`
  flex-flow: column;
  row-gap: 12px;
  padding: 16px 0 0;
`;

export const Divistion = styled.hr`
  ${({ theme }) => css`
    margin: 16px 0 40px;
    border: 1px solid ${theme.color.gray_30};
  `}
`;
