import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { checkPasswordLength, checkPasswordValidation } from "@/utils";
import { COMMON_VALID_MSG, convertMB } from "@/constants";
import type { AddressSearchType } from "@/types";

import { LOGISTICS_TOAST_MSG } from "constants/index";
import type { CompanyType, SignupForm } from "types";

const INIT_SIGNUP_FORM = {
  companyType: "carrier" as const,
  name: "",
  addr: {
    address: "",
    addressDetail: "",
    buildingName: "",
    city: "",
    coord: {
      lng: 0,
      lat: 0,
    },
  },
  taxcode: "",
  email: "",
  phone: "",
  license1: null,
  license2: null,
  transportLicense1: null,
  transportLicense2: null,
  managerFirstName: "",
  managerMiddleName: "",
  managerLastName: "",
  managerPhone: "",
  managerEmail: "",
  managerPassword: "",
  managerPasswordConfirm: "",
  checkTermsPolicy: false,
  checkTerms: false,
  checkPolicy: false,
};

const schema = yup.object({
  companyType: yup
    .mixed<CompanyType>()
    .required()
    .oneOf(["carrier", "forwarder", "forwardercarrier", "shipper"]),
  name: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  addr: yup
    .object({
      address: yup.string().required(COMMON_VALID_MSG.REQUIRED),
      addressDetail: yup.string().optional(),
      buildingName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
      city: yup.string().required(COMMON_VALID_MSG.REQUIRED),
      coord: yup
        .object({
          lat: yup.number().required(COMMON_VALID_MSG.REQUIRED),
          lng: yup.number().required(COMMON_VALID_MSG.REQUIRED),
        })
        .required(COMMON_VALID_MSG.REQUIRED),
    })
    .required(),
  taxcode: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  email: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  phone: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  license1: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .test({
      name: "required",
      test: (value) => !!value?.length,
      message: COMMON_VALID_MSG.REQUIRED,
    })
    .test({
      name: "oversize",
      message: "",
      test: (fileList) => {
        if (!fileList) return false;

        return Array.from(fileList).some(
          (file) => file.size <= 5 * 1024 * 1024,
        );
      },
    }),
  license2: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .test({
      name: "required",
      test: (value) => !!value?.length,
      message: COMMON_VALID_MSG.REQUIRED,
    })
    .test({
      name: "oversize",
      message: "",
      test: (fileList) => {
        if (!fileList) return false;

        return Array.from(fileList).some(
          (file) => file.size <= 5 * 1024 * 1024,
        );
      },
    }),
  transportLicense1: yup
    .mixed<FileList>()
    .optional()
    .nullable()
    .test({
      name: "oversize",
      message: "",
      test: (fileList) => {
        if (!fileList) return true;
        return Array.from(fileList).some(
          (file) => file.size <= 5 * 1024 * 1024,
        );
      },
    }),
  transportLicense2: yup
    .mixed<FileList>()
    .optional()
    .nullable()
    .test({
      name: "oversize",
      message: "",
      test: (fileList) => {
        if (!fileList) return true;
        return Array.from(fileList).some(
          (file) => file.size <= 5 * 1024 * 1024,
        );
      },
    }),
  managerFirstName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  managerMiddleName: yup.string().optional(),
  managerLastName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  managerPhone: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  managerEmail: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  managerPassword: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test({
      name: "validate",
      test: (value) =>
        checkPasswordValidation(value) && checkPasswordLength(value),
      message: COMMON_VALID_MSG.PASSWORD_STEP_VALID,
    }),
  managerPasswordConfirm: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test({
      name: "validate",
      test: function (value: string, contexts: yup.TestContext) {
        return value === contexts.resolve(yup.ref("managerPassword"));
      },
      message: COMMON_VALID_MSG.PASSWORD_CONFIRM,
    }),
  checkTermsPolicy: yup.boolean().required(COMMON_VALID_MSG.REQUIRED),
  checkTerms: yup
    .boolean()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test({
      name: "validate",
      test: (value) => value,
      message: COMMON_VALID_MSG.REQUIRED,
    }),
  checkPolicy: yup
    .boolean()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test({
      name: "validate",
      test: (value) => value,
      message: COMMON_VALID_MSG.REQUIRED,
    }),
});

const useSignupForm = () => {
  const { addToast } = useToast();

  const formMethod = useForm<SignupForm>({
    mode: "onTouched",
    defaultValues: INIT_SIGNUP_FORM,
    resolver: yupResolver(schema),
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const key = name as keyof SignupForm;

    if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
      formMethod.setError(key, { type: "size" });
      addToast(LOGISTICS_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
    }
  };

  const handleFileDelete = (fileKey: string) => () => {
    formMethod.setValue(fileKey as keyof SignupForm, null);
    formMethod.clearErrors(fileKey as keyof SignupForm);
  };

  const handleSelectAddr = (locationInfo: AddressSearchType) => {
    formMethod.setValue("addr.address", locationInfo.address);
    formMethod.setValue("addr.buildingName", locationInfo.buildingName);
    formMethod.setValue("addr.city", locationInfo.city);
    formMethod.setValue("addr.coord.lng", locationInfo.coord.lng);
    formMethod.setValue("addr.coord.lat", locationInfo.coord.lat);

    formMethod.trigger("addr");
  };

  return {
    formMethod,
    handleFileChange,
    handleFileDelete,
    handleSelectAddr,
  };
};

export default useSignupForm;
