export const PATH = {
  // NOTE: Public
  ROOT: "/",
  SIGN_UP: "/signup",
  EXPIRED: "/expired",
  NOT_FOUND: "/notFound",
  RESET_PASSWORD: "/resetPassword",
  CHANGE_PASSWORD: "/changePassword",

  // NOTE: Order creation
  ORDER_CREATION: "/orderCreation",

  // NOTE: Quotation
  QUOTATION: "/quotation",
  QUOTATION_DETAIL: "/quotation/:id",
  QUOTATION_SEND: "/quotation/send",

  // NOTE: Monitoring
  MONITORING: "/monitoring",

  // NOTE: Settlement
  SETTLEMENT: "/settlement",
  SETTLEMENT_DETAIL: "/settlement/:id",

  // NOTE: Allocation
  ALLOCATION: "/allocation",
  ALLOCATION_CREATE: "/allocation/create",
  ALLOCATION_EDIT: "/allocation/edit",

  // NOTE: Order Management
  ORDER_MANAGEMENT: "/orderManagement",
  ORDER_MANAGEMENT_DETAIL: "/orderManagement/:id",
  ORDER_MANAGEMENT_MAP: "/orderManagement/map",
  ORDER_MANAGEMENT_ALLOCATION: "/orderManagement/allocation",

  // NOTE: Property
  PROPERTY: "/property",
  PROPERTY_GARAGE_CREATE: "/property/garage/create",
  PROPERTY_GARAGE_EDIT: "/property/garage/edit",
  PROPERTY_CREATE: "/property/create",
  PROPERTY_DRIVER_CREATE: "/property/driver/create",
  PROPERTY_DRIVER_DETAIL: "/property/driver/:id",
  PROPERTY_DRIVER_ALLOCATION: "/property/driver/allocation",
  PROPERTY_TRUCK_CREATE: "/property/truck/create",
  PROPERTY_TRUCK_DETAIL: "/property/truck/:id",
  PROPERTY_TRUCK_ALLOCATION: "/property/truck/allocation",

  // NOTE: Settings
  SETTINGS: "/settings",
  SETTINGS_ACCOUNT: "/settings/account",
  SETTINGS_COMPANY: "/settings/company",
  SETTINGS_COMPANY_PLAN: "/settings/company/plan",
  SETTINGS_COMPANY_PLAN_EDIT: "/settings/company/planEdit",
  SETTINGS_COMPANY_LICENSE: "/settings/company/license",
  SETTINGS_MEMBER: "/settings/member",
  SETTINGS_CREATE_MEMBER: "/settings/member/create",
  SETTINGS_EDIT_MEMBER: "/settings/member/edit/:id",
  SETTINGS_NOTICE: "/settings/notice",
  SETTINGS_NOTICE_DETAIL: "/settings/notice/:id",
  SETTINGS_SUPPORT: "/settings/support",
  SETTINGS_SUPPORT_DETAIL: "/settings/support/:id",
  SETTINGS_CREATE_SUPPORT: "/settings/support/create",
  SETTINGS_PLAN: "/settings/plan",
} as const;
