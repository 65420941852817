import { COMMON_TOAST_MSG } from "@/constants";
import type { Languages } from "@/types";

const TOAST_MSG = {
  SUCCESS: {
    COMPANY_UPDATE_DONE: {
      type: "success",
      content: "Default information has been updated.",
    },
    MEMBER_ADD_DONE: {
      type: "success",
      content: "Member has been added.",
    },
    MEMBER_DELETE_DONE: {
      type: "success",
      content: "Member has been deleted.",
    },
    MEMBER_UPDATE_DONE: {
      type: "success",
      content: "Member information has been updated.",
    },
    INQURIY_ADD_DONE: {
      type: "success",
      content: "Your inquiry has been registered.",
    },
    LINK_CONNECT_DONE: { type: "success", content: "Link has been set." },
    LINK_DISCONNECT_DONE: {
      type: "success",
      content: "Link has been disabled.",
    },
    ALLOCATION_ADD_DONE: {
      type: "success",
      content: "Allocation has been completed.",
    },
    ALLOCATION_UPDATE_DONE: {
      type: "success",
      content: "Allocation information has been updated.",
    },
    ALLOCATION_DELETE_DONE: {
      type: "success",
      content: "Allocation has been canceled.",
    },
    GARAGE_ADD_DONE: { type: "success", content: "Garage has been added." },
    GARAGE_UPDATE_DONE: {
      type: "success",
      content: "Garage information has been updated.",
    },
    GARAGE_DELETE_DONE: {
      type: "success",
      content: "Garage has been deleted.",
    },
    DRIVER_ADD_DONE: { type: "success", content: "Driver has been added." },
    DRIVER_UPDATE_DONE: {
      type: "success",
      content: "Driver information has been updated.",
    },
    DRIVER_DELETE_DONE: {
      type: "success",
      content: "Driver has been deleted.",
    },
    DRIVER_PASSWORD_CHANGE_DONE: {
      type: "success",
      content: "Password has been changed.",
    },
    TRUCK_ADD_DONE: { type: "success", content: "Truck has been added." },
    TRUCK_UPDATE_DONE: {
      type: "success",
      content: "Truck information has been updated.",
    },
    TRUCK_DELETE_DONE: { type: "success", content: "Truck has been deleted." },
    PASSWORD_CHANGE_DONE: {
      type: "success",
      content: "Your password has been changed.",
    },
    REJECT_QUOTATION_DONE: {
      type: "success",
      content: "The quotation has been rejected.",
    },
    SEND_QUOTATION_DONE: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    APPROVE_QUOTATION_DONE: {
      type: "success",
      content: "Quotation approved successfully.",
    },
    EXCEED_FILE_SIZE: {
      type: "warning",
      content: "You can't upload more than 5MB.",
    },
    CREATE_ACCOUNT_SUCCESS: {
      type: "success",
      content: "Your account has been created successfully.",
    },
    CREATE_ACCOUNT_SUCCESS_VITENAM: {
      type: "success",
      content:
        "Chúng tôi đã nhận được thông tin đăng ký tài khoản của bạn. Khi thông tin được xác minh, chúng tôi sẽ gửi phản hồi đến email đã đăng ký của bạn" as Languages,
    },
  },
  WARNING: {
    PICK_DROP_SORT_NOT_OK: {
      type: "warning",
      content: "The drop-off cannot be placed above the pick-up.",
    },
    ALREADY_ALLOCATIOND: {
      type: "warning",
      content: "Driver has already been allocated to this order.",
    },
    NO_TRUCK_SELECTED: { type: "warning", content: "No truck is selected." },
    PROCESS_FAIL: {
      type: "warning",
      content:
        "Unable to process your request due to shipping schedule exists.",
    },
    DRIVER_ALREADY_LINK: {
      type: "warning",
      content:
        "Unable to process your request due to linked truck already exists.",
    },
    GARAGE_NAME_EXIST: {
      type: "warning",
      content: "This garage name is already taken.",
    },
    DELETE_GARAGE_EXISTING_TRUCK: {
      type: "warning",
      content: "Unable to process your request due to a parked truck exists.",
    },
    COMPANY_EMAIL_EXISTS: {
      type: "warning",
      content: "This business email is already registered.",
    },
    COMPANY_PHONE_EXISTS: {
      type: "warning",
      content: "This business tel. is already registered.",
    },
    STAFF_EMAIL_EXISTS: {
      type: "warning",
      content: "This Login ID is already taken.",
    },
    TAXCODE_EXISTS: {
      type: "warning",
      content: "This tax code is already registered.",
    },
    STAFF_PHONE_EXISTS: {
      type: "warning",
      content: "This phone number is already registered.",
    },
  },
} as const;

export const CARRIER_TOAST_MSG = {
  SUCCESS: { ...TOAST_MSG.SUCCESS, ...COMMON_TOAST_MSG.SUCCESS },
  WARNING: { ...TOAST_MSG.WARNING, ...COMMON_TOAST_MSG.WARNING },
};

export const FORWARDER_TOAST_MSG = {
  SUCCESS: { ...TOAST_MSG.SUCCESS, ...COMMON_TOAST_MSG.SUCCESS },
  WARNING: { ...TOAST_MSG.WARNING, ...COMMON_TOAST_MSG.WARNING },
};

export const FORWARDER_CARRIER_TOAST_MSG = {
  SUCCESS: { ...TOAST_MSG.SUCCESS, ...COMMON_TOAST_MSG.SUCCESS },
  WARNING: { ...TOAST_MSG.WARNING, ...COMMON_TOAST_MSG.WARNING },
};

//TODO: clientType 별로 나눈 toast 통합 필요
export const LOGISTICS_TOAST_MSG = {
  SUCCESS: { ...TOAST_MSG.SUCCESS, ...COMMON_TOAST_MSG.SUCCESS },
  WARNING: { ...TOAST_MSG.WARNING, ...COMMON_TOAST_MSG.WARNING },
};
